import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import Button from '../Button';
import { FRONTEND_URL } from '../../../utils/constants';
import s from './index.module.scss';

const ErrorOverlay = ({ statusCode }) => {
  /* We are checking for 200 OK, because the following edge case can occur:
   * Going back in the browser after following an internal route back to homepage
   * from this error page does not re-run any getInitialProps to determine statusCode
   * because the user navigated to this page deliberately, unlike the original error triggering it.
   * Therefore, show the 404 page, which works well in this context since the original error page is
   * now invalid anyway.
   */
  if (statusCode === 200 || statusCode === 404) {
    return (
      <div className="container">
        <div className={`${s['error-overlay']}`}>
          <Head>
            <title>Page not found | Concern Worldwide</title>
          </Head>

          <div className={s['error-overlay__info']}>
            <div className={s['error-overlay__info-image']}>
              <img src="/static/images/404.png" alt="404" width={663} height={667} />
            </div>

            <div className={s['error-overlay__info-text']}>
              <p>
                It looks like you’re lost.
                <br />
                <br />
                The page you have requested does not exist. You may have followed an outdated link{' '}
                or mistyped a URL.
              </p>

              <Button tag="a" href={FRONTEND_URL} type="secondary" isMobileBlock>
                Go to Concern home page
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className={`${s['error-overlay']} ${s['error-overlay__error']}`}>
        {statusCode >= 300 && statusCode < 400 && <>Redirecting...</>}
        {statusCode !== 404 && (statusCode < 300 || statusCode >= 400) && (
          <>
            <Head>
              <title>Error {statusCode} occurred | Concern Worldwide</title>
            </Head>
            <h2 className={s['error-overlay__heading']}>{statusCode}</h2>
            Something went wrong. Please try again.
          </>
        )}
      </div>
    </div>
  );
};

ErrorOverlay.propTypes = {
  statusCode: PropTypes.number.isRequired,
};

export default ErrorOverlay;
